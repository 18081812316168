* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  max-height: 100vh;
}

.custom-tooltip {
  font-size: 10px !important; /* Smaller text */
  padding: 2px 4px !important; /* Less padding */
  background: none !important; /* Remove default background */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow */
  white-space: nowrap !important; /* Keep text in a single line */
  z-index: 10 !important; /* Adjust as needed */
}

.substation-label {
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1.2; /* Adjust this value as needed */
  color: #4a3b3b; /* Dark text color */
  background-color: transparent !important; /* Ensure no visible background */
  border-radius: 0 0 0 var(--oim-radius-small);
  padding: 1px 2px !important; /* Less padding */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow */
  z-index: 20 !important; /* Adjust as needed */
  display: inline-block; /* Ensures it doesn’t shrink */
  min-width: 120px !important; /* Set a width limit */
  max-width: 240px !important; /* Set a width limit */
  white-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word !important; /* Ensure wrapping happens properly */
  overflow-wrap: break-word !important; /* Alternative for wider support */
  text-align: center !important; /* Keep text centered */
  font-family: sans-serif; /* Closest to OpenInfraMap */
  /* text-shadow: 1px 1px 3px rgb(75, 75, 75); */

  /* SOLID HALO EFFECT */
  text-shadow: -2px -2px 0 #d1d0d0, 2px -2px 0 #d1d0d0, -2px 2px 0 #d1d0d0,
    2px 2px 0 #d1d0d0, -4px -4px 4px #d1d0d0, 4px -4px 4px #d1d0d0,
    -4px 4px 4px #d1d0d0, 4px 4px 4px #d1d0d0;
}

.powerstation-label {
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1.2; /* Adjust this value as needed */
  color: #4a3b3b; /* Dark text color */
  background-color: transparent !important; /* Ensure no visible background */
  border-radius: 0 0 0 var(--oim-radius-small);
  padding: 1px 2px !important; /* Less padding */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow */
  z-index: 30 !important; /* Adjust as needed */
  display: inline-block; /* Ensures it doesn’t shrink */
  min-width: 120px !important; /* Set a width limit */
  max-width: 240px !important; /* Set a width limit */
  white-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word !important; /* Ensure wrapping happens properly */
  overflow-wrap: break-word !important; /* Alternative for wider support */
  text-align: center !important; /* Keep text centered */
  font-family: sans-serif; /* Closest to OpenInfraMap */
  /* text-shadow: 1px 1px 3px rgb(75, 75, 75); */

  /* SOLID HALO EFFECT */
  text-shadow: -2px -2px 0 #d1d0d0, 2px -2px 0 #d1d0d0, -2px 2px 0 #d1d0d0,
    2px 2px 0 #d1d0d0, -4px -4px 4px #d1d0d0, 4px -4px 4px #d1d0d0,
    -4px 4px 4px #d1d0d0, 4px 4px 4px #d1d0d0;
}

.generator-label {
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1.2; /* Adjust this value as needed */
  color: #4a3b3b; /* Dark text color */
  background-color: transparent !important; /* Ensure no visible background */
  border-radius: 0 0 0 var(--oim-radius-small);
  padding: 1px 2px !important; /* Less padding */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove any shadow */
  z-index: 30 !important; /* Adjust as needed */
  display: inline-block; /* Ensures it doesn’t shrink */
  min-width: 120px !important; /* Set a width limit */
  max-width: 240px !important; /* Set a width limit */
  white-space: normal !important; /* Allow text to wrap */
  word-wrap: break-word !important; /* Ensure wrapping happens properly */
  overflow-wrap: break-word !important; /* Alternative for wider support */
  text-align: center !important; /* Keep text centered */
  font-family: sans-serif; /* Closest to OpenInfraMap */
  /* text-shadow: 1px 1px 3px rgb(75, 75, 75); */

  /* SOLID HALO EFFECT */
  text-shadow: -2px -2px 0 #d1d0d0, 2px -2px 0 #d1d0d0, -2px 2px 0 #d1d0d0,
    2px 2px 0 #d1d0d0, -4px -4px 4px #d1d0d0, 4px -4px 4px #d1d0d0,
    -4px 4px 4px #d1d0d0, 4px 4px 4px #d1d0d0;
}

.generator-label::before,
.generator-label::after {
  display: none !important;
}
/* sizing of map container */
.leaflet-container {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  width: 100vw;
  z-index: 1;
  height: 100vh;
  /* border-radius: 10px; */
}
